@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

/* For Google Icons */
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
  font-size: 40px;
}

* {
    font-family: 'Handlee', cursive;
}
    
a {
    color: goldenrod;
}
a:hover {
    color: darkgoldenrod;
}

:root, html {
    background-color: darkslategray;
}
body, .App {
    max-height: 100vh;
    overflow: hidden;
}
.App {
    max-width: 90%;
    margin: 0 auto;
    padding: 40px 30px 20px;
    color: white;
    height: 100vh;
    max-height: 100vh;
}
header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
.login {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}
.login h1 {
    font-size: 48px;
}
.login p {
    font-size: 24px;
}
.logout {
    display: inline-block;
    padding: 5px;
}
.logout span {
    font-size: 24px;
}
input, input:focus, button {
    font-family: 'Handlee', cursive;
}
button {
    background-color: #1ED760;
    border-radius: 4px;
    padding: 20px 20px;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
}
.saveButton {
    display: flex;
    align-items: center;
}
.saveButtonText{
    line-height: 0;
    margin-left: 10px;
}
.table {
    margin-bottom: 30px;
    border: 4px solid goldenrod;
    border-radius: 4px;
}
.tHeaders {
    display: flex;
    flex-flow: row nowrap;
    background-color: darkgoldenrod;
    width: 100%;
    box-sizing: border-box;
}
.tHeaders div {
    width: 25%;
    padding: 20px;
    text-align: center;
}

.tbodyRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 4px solid goldenrod;
}

.tbodyRow div {
    padding: 10px;
    width: 25%;
    text-align: center;
}
.tbodyRow div img {
    max-width: 100px;
    max-height: 100px;
}
.tbodyRow div img:hover{
    cursor: pointer;
}

.tbodyRow:nth-child(even) {
    background-color: lightgoldenrodyellow;
    color: darkslategray
}
.mmm {
    color: darkgoldenrod;
}
input, input:focus {
    color: white;
    width: 100%;
    font-size: 38px;
    background-color: darkslategray;
    border: none;
    border-bottom: 4px dashed black;
    margin-bottom: 20px;
    border-color: black;
    outline: none; /* Remove the outline on focus */
    box-shadow: none; /* Remove the box shadow on focus */
}
.error {
    border-bottom: 4px dashed red!important;
} 
.addOrRemoveSong:hover {
    cursor: pointer;
}
.searchResults, .playlist {
    box-sizing: border-box;
    width: 49%;
    height: 90vh;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    padding-right: 10px;
    overflow: hidden;
}
.tracklist {
    box-sizing: border-box;
    padding-bottom: 20px;
    width: 100%;
    height: 80%;
    overflow: auto;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: lightgoldenrodyellow;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: darkgoldenrod;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: goldenrod;
}
#screenSizeText {
    display: none;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1130px) {
    .App {
        padding-top: 20px!important;
    }
    p {
        font-size: 20px!important;
    }
    #screenSizeText {
      display: block!important;
    }
    .login button {
        display: none!important;
    }
    #toUseText {
        display: none!important;
    }
}